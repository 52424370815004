import React, { Fragment } from 'react'
import styled from 'styled-components';
import { Block, BlockStyle } from './Block';
import StoreController from '../../StoreController';
import Spinner from "../../Components/Common/Spinner/Spinner";
import { api, isAdmin } from '../../utils/api';
import { API_ZAPIER, PAGECRAFT_API_URL } from '../../Constants';
import { rxProjectId, rxPageId, rxProducts, eventEmiter } from '../../rx/rxState';
import { graphQlCall } from 'graphql/utils';
import { useLocation } from 'react-router-dom';
import QUERIES from 'graphql/queries';

const ButtonStyle = styled.div`
    margin: auto;
    background-color: ${props=>props.backgroundColor};
    border-radius: ${props=>props.borderRadius};
    width: 100%;
    position: relative;
`

const LoadingStyle = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`

export class BlockButtonProxy extends React.Component {
    constructor(props) {
        super(props);
        this.targetType = props.targetType;
        this.state = {
            loading: false,
            error: null
        }
    }
    onChange(editorState) {
        this.setState({ content: editorState })
        this.props.onChange(editorState);
    }

    componentDidMount() {
        this.props.reference.current.style.cssText = this.props.styleText();
    }
    componentDidUpdate(prevProps) {
        // if (prevProps.styleText !== this.props.styleText) {
            this.props.reference.current.style.cssText = this.props.styleText();
        // }
    }
    onFormCallback(status, error, mode) {
        if (error) {
            this.setState({ loading: false });
        }
        if (status && !error) {
            if (mode === 'stripe') {
                let formData = JSON.parse(localStorage.getItem('key'));
                this.sendCustomerData(formData, (status, error) => this.onFormCallback(status, error));
            }

            this.nextPage();
        }
    }

    async nextPage() {
        if (this.props.destination.value == 'popup') {
            eventEmiter.next({
                type: 'switch-view-exit-modal'
            });
            this.setState({ loading: false })
        }
        else if (this.props.destination.value == 'custom') {
            if (this.props.url.value) {
                window.location += this.props.url.value

                // this.navigate();
            }
            this.setState({ loading: false })
        }
        else if (this.props.destination.value == 'URL') {
            if (this.props.url.value) {
                let url = this.props.url.value;
                if (this.props.url.value.indexOf('https://') === -1 && this.props.url.value.indexOf('http://') === -1) {
                    url = 'https://' + this.props.url.value;
                }

                this.navigate(url);
            }
            this.setState({ loading: false })
        }
        else {
            const result = await graphQlCall({
                queryTemplateObject: QUERIES.GET_NEXT_PAGE_QUERY,
                values: {
                    pageId: rxPageId.getValue(),
                    funnelId: rxProjectId.getValue()
                }
            });
            let url = window.location.href;
            if (result.next) {
                let sliceAmount = -2;

                let components = url.split('/');
                if (components[components.length - 1] != 'optin') { //TODO remove this dirty hardcoded workaround
                    sliceAmount = -1;
                }
                let destination = url.split('/').slice(0, sliceAmount).join('/') + '/' + result.next + '/optin'
                destination += window.location.search //pass all URL querry params to next page ( needs for UTM variables and such )
                this.navigate(destination);
            }
            else {
                this.setState({ loading: false })
            }
        }
    }

    navigate(url) {
        if (this.targetType.value === 'New Tab') {
            window.open(url, '_blank');
        }
        else if (this.targetType.value === 'Same Page') {
            window.open(url, '_top');
        } else {
            window.open(url);
        }
        this.setState({ loading: false })
    }
    getFormValue(key, formData){
        for(const data of formData){
            if(data.type === key){
                return data.value;
            }
        }
    }
    async onClick() {
        if (StoreController.instance().liveMode === false) {
            return;
        }
        if(this.state.loading){
            return;
        }

        this.setState({error: null});
        
        let pass = true;
        let formData = [];
        let rootSection = this.props.view.getRootSectionFor(this.props.block);
        let forms = rootSection.getBlocksByType('Form');
        for (let form of forms) {
            if (form.isVisible === false) {
                continue;
            }
            let data = form.verifyInput();
            if (data === false) {
                pass = false;
            }
            else {
                if (form.formType !== 'Checkbox') {
                    formData.push(data);
                }
            }
        }
        if (!this.props.formValidation) {
            pass = true;
        }

        let stripe = this.props.view.getBlocksByType('Stripe');
        for (let form of stripe) {
            if (!form.verifyInput() && pass) {
                pass = false;
            }
        }

        if (!pass) {
            return;
        }
        
        //save form data in to cache
        if (formData.length > 0) {
            localStorage.setItem('key', JSON.stringify(formData));
        }
        if (stripe.length > 0) {
            this.setState({ loading: true });
            stripe[0].createPaymentIntent(formData, (status, error) => this.onFormCallback(status, error, 'stripe'));
        }
        else if (formData.length > 0) {
            this.setState({ loading: true });
            

            if (this.props.destination.value === 'BookleFree'){
                this.sendCustomerData(formData, async (status, error) => {
                    const email = this.getFormValue('Email', formData);
                    if(email){
                        const queryParams = new URLSearchParams(window.location.search);
                        let values = { email:email }
                        if(queryParams.get('rid')){
                            values['referalId'] = queryParams.get('rid');
                        }
                        try{
                            const result = await graphQlCall({
                                queryTemplateObject: QUERIES.START_FREE_SUBSCRIPTION,
                                values: values
                            });
                            this.setState({ loading: false });
                            window.open(result.link+'?app=bookle', '_top');
                            }
                        catch(error){
                            this.setState({ loading: false });
                            const errorMessage = error.message.split(':')[1];//TODO: need to find a better way to extract proper messages from Graph QL errors 
                            console.error('error:', errorMessage); 
                            this.setState({error: errorMessage});
                        }
                    }
                });
            }else{
                this.sendCustomerData(formData, (status, error) => this.onFormCallback(status, error));
            }
        }
        else if (this.props.destination.value === 'upsell') {
            this.setState({ loading: true });
            let formData = JSON.parse(localStorage.getItem('key'));

            let dict = {};
            for (const d of formData) {
                dict[d.type] = d.value;
            }
            this.sendUpsellData({
                 ...dict, 
                 priceID: this.props.priceId,
                 productID: this.props.productId 
                },
                (status, error) => this.onFormCallback(status, error, 'stripe'));
        }
        else {
            this.setState({ loading: true })
            this.nextPage();
        }
    }


    async sendUpsellData(data, callback) {
        const payload = {
            projectId: rxProjectId.getValue(),
            ...data
        }
        if (payload['First Name']) {
            payload.FirstName = payload['First Name'];
            delete (payload['First Name']);
        }
        if (payload['Last Name']) {
            payload.LastName = payload['Last Name'];
            delete (payload['Last Name']);
        }
        try {
            await graphQlCall({
                queryTemplateObject: QUERIES.CREATE_UPSELL_PAYMENT_INTENT,
                values: { ...payload, lm_data: window.lm_data, }
            });
            callback(true);
        } catch (err) {
            callback(false, err);
        }
    }

    sendCustomerData(data, callback) {
        let dict = {};
        for (let item of data) {
            dict[item.type] = item.value;
        }
        let request = {
            data: {
                firstName: dict.FirstName || dict.Name,
                lastName: dict.LastName,
                email: dict.Email,
                phone: dict.PhoneNumber,
            },
            funnelId: rxProjectId.getValue(),
            pageId: rxPageId.getValue(),
            type: "CREATE_CUSTOMER"
        }

        api(`${PAGECRAFT_API_URL}/zapier/push`, 'POST', request)
            .then((result) => {
                if (result.error) {
                    callback(false, result.error);
                }
                else {
                    callback(true);
                }
            })
            .catch(error => {
                console.log('error:', error);
                callback(false, error);
            });
    }

    render() {
        let content = <ButtonStyle
            borderRadius = {this.props.borderRadius}
            backgroundColor = {this.props.backgroundColor}
        >
            {this.state.loading ?
                <LoadingStyle>
                    <Spinner size={40} />
                </LoadingStyle>
                :
                null
            }


            <div style={{ opacity: this.state.loading ? '0' : '1', margin: '15px auto 15px auto'}}>
                {this.props.children}
                {this.state.error &&
                    <div style={{position:'absolute',  width:'100%', display:'flex'}}>
                        <div style={{textAlign:'center',margin: 'auto', background: '#f26262', padding:'0px 5px', borderRadius:'8px', color:'white', fontWeight:'800'}}>
                            {this.state.error}
                        </div>
                    </div>
                }
            </div>
        </ButtonStyle>

        let output = <BlockStyle
            id={this.props.id}
            key={this.props.id}
            onClick={this.onClick.bind(this)}
            className={this.props.className}
            // width={this.props.width}
            ref={this.props.reference}>
            {content}
        </BlockStyle>

        return output;
    }
}

export class BlockButton extends Block {
    constructor(props) {
        super(props);

        this.type = 'Button';

        this.className = 's-button-solid';

        let attr = {
            id: 'color',
            displayName: 'Color',
            value: "#49BAFF",
            type: 'AttributeColor'
        }
        this.addAttribute(attr);
        attr = {
            id: 'borderRadius',
            displayName: 'Border radius',
            value: '30px',
            type: 'AttributeDropdown',
            options: [
                {
                    label: 'Completely round',
                    value: '30px'
                },
                {
                    label: 'Round corners',
                    value: '10px'
                },
                {
                    label: 'Square',
                    value: '0'
                },
            ],
        }
        this.addAttribute(attr);
        attr = {
            id: 'destination',
            displayName: 'Destination',
            value: 'Next Page',
            type: 'AttributeDropdown',
            options: [{
                label: 'Next Page',
                value: 'Next Page'
            },
            {
                label: 'Popup',
                value: 'popup'
            },
            {
                label: '1-Click Upsell',
                value: 'upsell'
            },
            {
                label: 'URL',
                value: 'URL'
            },
            {
                label: 'Custom',
                value: 'custom'
            }],
        }
        //Adding special custom action for Autofunnel to start Free Subscription
        if(isAdmin()){
            attr.options.push({label: 'Bookle Free', value:'BookleFree'});
        }
        this.addAttribute(attr);

        attr = {
            id: 'targetType',
            displayName: 'Open As',
            value: 'Same Page',
            type: 'AttributeDropdown',
            options: [{
                label: 'Same Page',
                value: 'Same Page'
            },
            {
                label: 'New Tab',
                value: 'New Tab'
            }],
        }
        this.addAttribute(attr);

        attr = {
            id: 'url',
            displayName: '',
            value: '',
            width: 200,
            prefix: '', /* was 'https://'  */
            type: 'AttributeString',
            visible: false
        }
        this.addAttribute(attr);

        attr = {
            id: 'formValidation',
            displayName: 'Forms Validation',
            value: false,
            type: 'AttributeBool',
        }
        this.addAttribute(attr);

        let attribute = {
            id: 'product',
            displayName: 'Product',
            value: '',
            type: 'AttributeDropdown',
            options: []
        }
        this.addAttribute(attribute);

        attribute = {
            id: 'price',
            displayName: 'Price',
            value: '',
            type: 'AttributeDropdown',
            options: []
        }
        this.addAttribute(attribute);

        rxProducts.subscribe({
            next: (v) => this.updateProducts(v)
        })

        this.updateProducts(rxProducts.value);
    }

    updateProducts(products){

        let productOptions = [];
        for (let product of products ) {
            let id;
            if(product.id){
                id = product.id
            }
            else{
                id = product.name;
            }
            productOptions.push({
                label: product.name,
                value: id
            })
        }
        this.product.options = productOptions;
    }

    update() {
        if (this.destination.value === 'URL' || this.destination.value == 'custom') {
            this.url.visible = true;
            this.product.visible = false;
            this.price.visible = false;
        }
        else if (this.destination.value === 'upsell') {
            this.product.visible = true;
            this.price.visible = true;
            this.url.visible = false;
        }
        else {
            this.url.visible = false;
            this.product.visible = false;
            this.price.visible = false;
        }

        let priceOptions = [];
        for( const product of rxProducts.value){
            let id; 
            if(product.id){
                id = product.id;
            }
            else{
                id = product.name;
            }
            if(id === this.product.value){
                if(product.prices){
                    for(const price of product.prices){
                        priceOptions.push({
                            label: price.unit_amount / 100 + ' ' + price.currency,
                            value: price.id
                        })    
                    } 
                    this.price.visible = true;   
                }
                else{
                    this.price.visible = false;
                }
                break;
            }
        }
        this.price.options = priceOptions;

        super.update();
    }

    setEditing(value) {
        let textBlock = this.children[0];
        if (value) {
            this.view.overlay.selectBlock(textBlock);
        }
        textBlock.setEditing(value);
    }

    style() {
        let output = super.style();

        if (StoreController.instance().liveMode) {
            output += '; cursor: pointer;'
        }
        return output;
    }
    unpack(data) {
        super.unpack(data);

        //temp: workaround related to issue where text on buttons can't be re-selected properly 
        for (let child of this.children) {
            child.isDraggable = true;
        }
    }
    renderView() {
        return (
            <Fragment key={this.id}>
                {this.isVisible ?
                    <>
                        {/* {this.isDragging ? this.getPlaceholder()
                            : */}
                        <BlockButtonProxy
                            id={this.id}
                            key={this.id}
                            view={this.view}
                            reference={this.ref}
                            ref={this.proxyRef}
                            width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
                            styleText={this.style}
                            targetType={this.targetType}
                            destination={this.destination}
                            productId={this.product.value}
                            priceId={this.price.value}
                            formValidation={this.formValidation.value}
                            block={this}
                            url={this.url}
                            className={this.className}
                            backgroundColor={this.color.value}
                            borderRadius={this.borderRadius.value}

                        >
                            {
                                this.children.map((child) => {
                                    return child.renderView();
                                })
                            }
                        </BlockButtonProxy>
                        {/* } */}
                    </>
                    :
                    null
                }
            </Fragment>
        )
    }
}
